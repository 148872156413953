import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const MultiSelectWithvalue = (props) => {
  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState(null);
  const [fieldName, setFieldName] = useState("")
  const [closeOnSelect, setCloseOnSelect] = useState(false)
  const handleChange = (selectedOption,e) => {
	  //console.log("handleChange");
	  //console.log(e);
    // const index = tags.indexOf(tag => tag === selectedOption)
    //console.log('selectedOption',selectedOption)
    setSelectedTags(selectedOption);
    props.handleChange(selectedOption,e)
  };
  

  useEffect(() => {
    if(props.data){
      //console.log("props");
      //console.log(props.data);
      const options = props.data.map(data => ({
    	value: ( typeof(data.label) !== "undefined" ) ?  data.label : data,
        label: ( typeof(data.label) !== "undefined" ) ?  data.label : data,
      }));
      setTags(options)
    }
    if(props.name){
    	setFieldName(props.name)
    }
    if(props.handleClose){
    	setCloseOnSelect(props.handleClose)
    }
    //console.log("prop value");
    //console.log(props.value);
    if(props.value){
      const options = props.value.map(data => ({
        value: data,
        label: data,
      }));
      setSelectedTags(options)
    }
    
  },[props])
   
// console.log('tags', tags)
// console.log('selectedTags', selectedTags)
  return (
    <div>
        <Select className=''
       name={fieldName}	
      closeMenuOnSelect={closeOnSelect}
      components={animatedComponents}
      value={selectedTags}
      isMulti
      options={tags}
      onChange={handleChange}
    />
    </div>
  )
}

export default MultiSelectWithvalue