import React, {useState, useEffect, Fragment} from 'react';
// import Logo from '../assets/images/XMLID_2_.svg';
import Logo from '../assets/images/imgr.svg';
import LoginBG from '../assets/images/login-bg.png';
import {Button, Col, Container, Form, InputGroup, Row} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import {TailSpin} from 'react-loader-spinner';
import io from 'socket.io-client';
import fgLogo from "../assets/logo/fg-logo.png";

const socket = io(process.env.REACT_APP_BASE_URL);
var createGuest = require("cross-domain-storage/guest");
var createHost = require("cross-domain-storage/host");
var storageHost = createHost([
    {
        origin: "http://localhost:3000",
        allowedMethods: ["get", "set", "remove"],
    },
    {
        origin: "http://localhost:3001",
        allowedMethods: ["get"],
    },
]);

const Login = (props) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [loading, setLoading] = useState(false);
    const [passwordHideShow, setPasswordHideShow] = useState(true);

    // const handleLogin = async () => {
    //     if (!email || !password) {
    //         toast.error('email or password required')
    //     }else{
    //         const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/login`,{
    //             method:'POST',
    //             headers:{'Content-Type':'application/json'},
    //             body:JSON.stringify({email,password})
    //         }).then(res => {return res.json()})
    //             .then(data => {return data})
    //             if (response.status){
    //                 const user = response.data
    //                     socket.emit("setup", user);
    //                     props.setToken(user?.token)
    //                     localStorage.setItem('userdata', JSON.stringify(user))
    //                     navigate('/')
    //                 }else{
    //                 toast.error(response.message)
    //             }
    //     }
    // }
    const handleLogin = async () => {
        if (!email || !password) {
            toast.error('Email or password required');
        } else {
            setLoading(true); // Set loading to true when login starts

            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/login`, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({email, password}),
                }).then((res) => res.json());

                if (response.status) {
                    const user = response.data;
                    socket.emit('setup', user);
                    props.setToken(user?.token);
                    localStorage.setItem('userdata', JSON.stringify(user));
                    navigate('/');
                } else {
                    toast.error(response.message);
                }
            } catch (error) {
                console.error('Login error:', error);
                toast.error('An error occurred during login.');
            } finally {
                setLoading(false); // Set loading to false when login is complete (either success or failure)
            }
        }
    };

    const handleVisitor = async () => {
        await fetch(`${process.env.REACT_APP_BASE_URL}/admin/visitor`, {
            method: 'POST'
        })
    }

    useEffect(() => {
        // const wpStorage = createGuest('http://localhost/')
        localStorage.removeItem('userdata')
        handleVisitor()
    }, [])

    return (
        <Fragment>
            <ToastContainer/>
            {loading && (
                <div className="loader-overlay">
                    <TailSpin
                        height={80}
                        width={80}
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius={1}
                        visible={true}
                    />
                </div>
            )}
            <Container fluid className="login">
                <Row>
                    <Col className="d-flex justify-content-center align-items-center">
                        <div className="login-left w-70">
                            <div className="d-flex justify-content-center flex-column align-items-center">
                                <img src={fgLogo} alt="Logo" width="150"/>
                                <p className='text-custom-grey mt-3'>Login to get started!</p>
                            </div>
                            <div className='login-form mt-5'>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className='fw-600'>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email"
                                                  autoComplete="true"
                                                  autoFocus="true"
                                                  onChange={(e) => setEmail(e.target.value)}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label className='fw-600'>Password</Form.Label>
                                    <InputGroup>
                                        <Form.Control type={passwordHideShow ? 'password' : 'text'} placeholder="Password" autoComplete='false'
                                                      onChange={(e) => setPassword(e.target.value)}/>
                                        <Button variant="outline-secondary"
                                                className="password-toggle"
                                                onClick={() => setPasswordHideShow(!passwordHideShow)}>
                                            {passwordHideShow ?
                                                <svg xmlns="http://www.w3.org/2000/svg" height="26" width="28"
                                                     viewBox="0 0 576 512">
                                                    <path
                                                        d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"/>
                                                </svg> :
                                                <svg xmlns="http://www.w3.org/2000/svg" height="26" width="28"
                                                     viewBox="0 0 640 512">
                                                    <path
                                                        d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm51.3 163.3l-41.9-33C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5zm-88-69.3L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8z"/>
                                                </svg>
                                            }
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                                <Button variant="primary" type="submit"
                                        className='d-block bg-green rounded text-white w-100 border-0 fs-4 mt-5 fst-normal py-2 custom-shadow text-decoration-none text-center'
                                        onClick={handleLogin}>
                                    Login
                                </Button>
                                <div className="d-flex justify-content-center justify-content-center mt-3 gap-3">
                                    <div className="d-inline fs-5">Don't have an account?</div>
                                    <Link to="/"
                                          className='text-green d-inline me-5 fst-normal fs-5 text-decoration-none'>Join
                                        Now!</Link>
                                </div>
                                <div className='mt-sm-5 text-center pt-3'>
                                    <Link to="/" className='text-green d-inline me-5 fs-5 text-decoration-none'>Terms &
                                        Conditions</Link>
                                    <Link to="/" className='text-green d-inline fs-5 text-decoration-none'>Privacy
                                        Policy</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="justify-content-center align-items-end login-right d-none d-lg-flex">
                        <img src={LoginBG} alt="Login Back Ground"/>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
export default Login;
