import React, {useState, useEffect, Fragment} from 'react';
import {Routes, Route, useNavigate, Outlet} from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import UserSidebar from '../User_components/UserSidebar';
import TopBar from '../components/TopBar';
import Admindashboard from "../pages/Admindashboard";
import YourProfile from '../pages/YourProfile';
import Users from '../pages/Users';
import UserDeatils from '../pages/UserDeatils';
import Message from '../pages/Message';
import Shop from '../pages/Shop';
import Announcement from '../pages/Announcement';
import Faq from '../pages/Faq';
import Recipe from '../pages/Recipe';
import ViewRecipe from '../pages/ViewRecipe';
import EditRecipe from '../pages/EditRecipe';
import AddRecipe from '../pages/AddRecipe';
import CreateRecipe from '../pages/CreateRecipe';
import HowToVideos from '../pages/HowToVideos';
import VideoDetail from '../pages/VideoDetail';
import AddVideo from '../pages/AddVideo';
import Database from '../pages/Database';
import Plan from '../pages/Plan';
import ViewMealPlan from '../pages/ViewMealPlan';
import AddPlan from '../pages/AddPlan';
import WorkOutCalendar from '../pages/WorkOutCalendar';
import AddWorkout from '../pages/AddWorkout';
import CreateWorkout from '../pages/CreateWorkout';
import CreateWorkoutCard from '../pages/CreateWorkoutCard';
import WorkoutView from '../pages/WorkoutView';
import Dashboard from '../user_pages/Dashboard';
import UserRecipe from '../user_pages/UserRecipe';
import SavedPlan from '../user_pages/SavedPlan';
import FavoriteRecipe from '../user_pages/FavoriteRecipe';
import ExerciseDatabase from '../user_pages/ExerciseDatabase';
import FavoriteWorkout from '../user_pages/FavoriteWorkout';
import UserProfile from '../user_pages/UserProfile';
import UserShop from '../user_pages/UserShop';
import UserVideos from '../user_pages/UserVideos';
import UserFaq from '../user_pages/UserFaq';
import ShareEarn from '../user_pages/ShareEarn';
import MessageSupport from '../user_pages/MessageSupport';
import UserWorkoutCalendar from '../user_pages/UserWorkoutCalendar';
import MealPlanner from '../user_pages/MealPlanner';
import {Button, ButtonGroup, Col, Form, ProgressBar, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Salad from '../assets/images/greek-salad.png';
import UserImg from '../assets/images/admin.png';
import SplineChart from '../components/SplineChart';
import {differenceInDays} from 'date-fns'
import {useAuth} from '../context/AuthProvider'
import moment from 'moment';
import io from 'socket.io-client';
import {da} from "date-fns/locale";
import fgLogo from "../assets/logo/fg-logo.png";
import textLogo from "../assets/logo/text-logo.png";


const socket = io(process.env.REACT_APP_BASE_URL);

export default function Home({children}) {
    const navigate = useNavigate()
    const {user, isAuthenticated, showSidebar, setShowSidebar} = useAuth()
    // const [latestRecipes, setLatestRecipes] = useState([])
    // const [latestMealPlans, setLatestMealPlans] = useState([])
    // const [latestUsers, setLatestUsers] = useState([])
    // const [latestMessages, setLatestMessages] = useState([])
    // const [visitorSearchValue, setVisitorSearchValue] = useState('lastweek')
    // const [visitorData, setVisitorData] = useState([])
    // const [activeMembers, setActiveMembers] = useState(0)
    // const [monthlySubscriber, setMonthlySubscriber] = useState(0)
    // const [yearlySubscriber, setYearlySubscriber] = useState(0)


    // const fetchRecipesData = async () => {
    //   const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/getLatestRecipes`, {
    //     method: 'GET',
    //     headers: {
    //       "Content-Type": "application/json"
    //     }
    //   }).then(res => res.json()).then(data => { return data })
    //   if (response) {
    //     setLatestRecipes(response.data)
    //   } else {
    //     setLatestRecipes([])
    //   }
    // }
    // const fetchMealPlansData = async () => {
    //   const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/getLatestMealPlans`, {
    //     method: 'GET',
    //     headers: {
    //       "Content-Type": "application/json"
    //     }
    //   }).then(res => res.json()).then(data => { return data })
    //   if (response) {
    //     // let mealPlanData = response.data
    //     // mealPlanData.map(mealPlan => {
    //     //   mealPlan['diffDays'] = differenceInDays(new Date(), new Date(mealPlan.updatedAt))

    //     // })
    //     setLatestMealPlans(response.data)
    //   } else {
    //     setLatestMealPlans([])
    //   }
    // }

    // const fetchUsersData = async (req, res) => {
    //   const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/getLatestUsers`, {
    //     method: 'GET',
    //     headers: {
    //       "Content-Type": "application/json"
    //     }
    //   }).then(res => res.json()).then(data => { return data })
    //   if (response) {
    //     setLatestUsers(response.data)
    //   } else {
    //     setLatestUsers([])
    //   }
    // }

    // const fetchMessagesData = async (req, res) => {
    //   const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/getLatestMessages`, {
    //     method: 'GET',
    //     headers: {
    //       "Content-Type": "application/json"
    //     }
    //   }).then(res => res.json()).then(data => { return data })
    //   if (response) {
    //     setLatestMessages(response.data)
    //   } else {
    //     setLatestMessages([])
    //   }
    // }

    // const fetchVisitorData = async () => {

    //   const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/getVisitorData`, {
    //     method: 'POST',
    //     headers: {
    //       "Content-Type": "application/json"
    //     },
    //     body: JSON.stringify({ dataParam: visitorSearchValue })
    //   }).then(res => res.json()).then(data => {
    //     console.log('visitor data', data)
    //     return data
    //   })
    //   if (response) {
    //     console.log('response.chartData', response.chartData)
    //     setVisitorData(response.chartData)
    //   } else {
    //     setVisitorData([])
    //   }
    // }

    // const fetchActiveMembers = async () => {
    //   const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/getActiveMembers`, {
    //     method: 'GET',
    //     headers: {
    //       "Content-Type": "application/json"
    //     },

    //   }).then(res => res.json()).then(data => { return data })
    //   if (response) {
    //     setActiveMembers(response.data.length)
    //   } else {
    //     setActiveMembers(0)
    //   }
    // }

    // const fetchMonthlySubscribers = async () => {
    //   const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/getMonthlySubscriber`, {
    //     method: 'GET',
    //     headers: {
    //       "Content-Type": "application/json"
    //     },

    //   }).then(res => res.json()).then(data => { return data })
    //   if (response.status) {
    //     setMonthlySubscriber(response.data.length)
    //   }
    // }

    // const fetchYearlySubscribers = async () => {
    //   const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/getAnnualSubscriber`, {
    //     method: 'GET',
    //     headers: {
    //       "Content-Type": "application/json"
    //     },

    //   }).then(res => res.json()).then(data => { return data })
    //   if (response) {
    //     setYearlySubscriber(response.data.length)
    //   } else {
    //     setYearlySubscriber(0)
    //   }
    // }
    // useEffect(() => {
    //   fetchVisitorData()
    // }, [visitorSearchValue])

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login')
        }
    }, [user, isAuthenticated])

    const [screen, setScreen] = useState('sidebar-desktop');
    const [sidebar, setSidebar] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) { // Adjust breakpoint as needed
                setScreen('sidebar-mobile')
                setSidebar(false);
                setShowSidebar(false)
            } else {
                setScreen('sidebar-desktop')
                setSidebar(false);
                if (localStorage.getItem('showSidebar')) {
                    setShowSidebar(localStorage.getItem('showSidebar') === 'true');
                } else {
                    setShowSidebar(false);
                    localStorage.setItem('showSidebar', 'false');
                }
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Run initially

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={`dashboard-layout ${screen} ${showSidebar ? 'sidebar-show' : 'sidebar-hide'}`}>
            {(user?.userType === 'admin' ? <Sidebar user={user}/> : <UserSidebar user={user}/>)}
            <div className={`dashboard-content ${showSidebar ? 'dash-active' : ''}`}>
                {screen === 'sidebar-mobile' &&
                    <div className="navigation">
                        <Button className="icon" onClick={() => {
                            setShowSidebar(!showSidebar)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.5 5.00001C2.5 4.53977 2.8731 4.16667 3.33333 4.16667H16.6667C17.1269 4.16667 17.5 4.53977 17.5 5.00001C17.5 5.46024 17.1269 5.83334 16.6667 5.83334H3.33333C2.8731 5.83334 2.5 5.46024 2.5 5.00001Z" fill="#111827"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.5 10C2.5 9.53977 2.8731 9.16667 3.33333 9.16667H10.8333C11.2936 9.16667 11.6667 9.53977 11.6667 10C11.6667 10.4602 11.2936 10.8333 10.8333 10.8333H3.33333C2.8731 10.8333 2.5 10.4602 2.5 10Z" fill="#111827"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.5 15C2.5 14.5398 2.8731 14.1667 3.33333 14.1667H16.6667C17.1269 14.1667 17.5 14.5398 17.5 15C17.5 15.4602 17.1269 15.8333 16.6667 15.8333H3.33333C2.8731 15.8333 2.5 15.4602 2.5 15Z" fill="#111827"/>
                            </svg>
                        </Button>
                        <div className="main-logo">
                            <div className="logo">
                                <img src={textLogo} alt="Logo"/>
                            </div>
                        </div>
                    </div>
                }
                {screen === 'sidebar-desktop' && <TopBar/>}
                <div className='main-content container-fluid p-4'>
                    <Routes>
                        {
                            user?.userType === 'admin'
                                ? <Route path='/' element={<Admindashboard/>}/>
                                : <Route path="/" element={<Dashboard/>}/>
                        }
                    </Routes>
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}