import React, {useState, Fragment, useEffect} from 'react';
import "../assets/css/Sidebar.css";
import {NavLink, useLocation} from 'react-router-dom';
import {useAuth} from '../context/AuthProvider'
import fgLogo from "../assets/logo/fg-logo.png";
import textLogo from "../assets/logo/text-logo.png";
import {Accordion, Button} from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import 'react-tooltip/dist/react-tooltip.css';
import {Tooltip} from "react-tooltip";
import {ReactComponent as Dashboard} from '../assets/sidebar/Dashboard.svg';
import {ReactComponent as DownArrow} from '../assets/sidebar/DownArrow.svg';
import {ReactComponent as Nutrition} from '../assets/sidebar/Nutrition.svg';
import {ReactComponent as Exercise} from '../assets/sidebar/Exercise.svg';
import {ReactComponent as Support} from '../assets/sidebar/Support.svg';
import {ReactComponent as Shop} from '../assets/sidebar/Shop.svg';

const dataMenu = [
    {
        id: 'Dashboard',
        icon: <Dashboard/>,
        title: 'Dashboard',
        to: '/'
    },
    {
        id: 'Nutrition',
        icon: <Nutrition/>,
        title: 'Nutrition',
        subMenus: [
            {
                title: 'View Recipes',
                to: '/recipe'
            },
            {
                title: 'Manual Recipes',
                to: '/manual-recipes'
            },
            {
                title: 'Meal Planner',
                to: '/meal-planner'
            },
            {
                title: 'Saved Meal Plans',
                to: '/plan'
            },
            {
                title: 'Favorite Recipes',
                to: '/favorite-recipe'
            },
            {
                title: 'User Recipes',
                to: '/my-recipe'
            }
        ]
    },
    {
        id: 'Exercise',
        icon: <Exercise/>,
        title: 'Exercise',
        subMenus: [
            {
                title: 'Workout Calendar',
                to: '/workout-calendar'
            },
            {
                title: 'Exercise Database',
                to: '/database'
            },
            {
                title: 'Favorite Workouts',
                to: '/favorite-workout'
            },
            {
                title: 'Exercise Feedback',
                to: '/exercise-feedback'
            },
            {
                title: 'Workout Schedule',
                to: '/workout-schedule'
            },
            {
                title: 'Workout Generator',
                to: '/workout-generator'
            }
        ]
    },
    {
        id: 'Support',
        icon: <Support/>,
        title: 'Support',
        subMenus: [
            {
                title: 'FAQ\'s',
                to: '/faq'
            },
            {
                title: 'How to Videos',
                to: '/how-to-videos'
            },
            {
                title: 'Message Users',
                to: '/message'
            }
        ]
    },
    {
        id: 'Admin',
        icon: <Support/>,
        title: 'Admin',
        subMenus: [
            {
                title: 'Users',
                to: '/user'
            },
            {
                title: 'Announcement Bar',
                to: '/announcement'
            },
            {
                title: 'Shop',
                to: '/shop'
            },
            {
                title: 'Referral Pogram',
                to: '/referral-pogram'
            },
            {
                title: 'Admin Users',
                to: '/admin-users'
            }
        ]
    }
]

export default function Sidebar() {
    const {user, logout, showSidebar, setShowSidebar} = useAuth()
    const {pathname} = useLocation()
    const [accordionActiveKey, setAccordionActiveKey] = useState('');

    useEffect(() => {
        const parentMenuId = dataMenu.reduce((result, menu) => {
            if (menu.subMenus && menu.subMenus.some(subMenu => subMenu.to === pathname)) {
                result = menu.id;
                return result;
            }
            return result;
        }, null);
        setAccordionActiveKey(parentMenuId);
    }, [pathname]);

    return (
        <Fragment>
            <div className={`sidebar d-flex flex-column flex-shrink-0 p-3 bg-light ${showSidebar ? '' : 'hide'}`}>
                <div className="navigation">
                    <Button className="icon" onClick={() => {
                        setShowSidebar(!showSidebar)
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M4.16748 4.16667L15.8334 15.8326" stroke="#151B33" strokeWidth="1.5"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4.16664 15.8326L15.8325 4.16667" stroke="#151B33" strokeWidth="1.5"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </Button>
                    <div className="main-logo">
                        <div className="logo">
                            <img src={fgLogo} alt="Logo"/>
                            <img src={textLogo} alt="Logo"/>
                        </div>
                    </div>
                </div>
                <div className="sections">
                    <Accordion as="ul" className="nav nav-pills flex-column mb-auto mt-4"
                               activeKey={accordionActiveKey}>
                        {dataMenu.map((menu) => {
                            if (menu.hasOwnProperty('subMenus')) {
                                if (!showSidebar) {
                                    return <OverlayTrigger key={menu.id} trigger="click" placement="right-start"
                                                           rootClose={true} overlay={(
                                        <Popover className="sub-menu">
                                            <Popover.Body as='ul' className="nav nav-pills flex-column">
                                                {menu.subMenus.map((subMenu, index) => {
                                                    return <li key={index} className="nav-item">
                                                        <NavLink className="nav-link" to={subMenu.to}
                                                                 onClick={() => document.body.click()}>
                                                            {subMenu.title}
                                                        </NavLink>
                                                    </li>
                                                })}
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                        <li className="nav-item" data-tooltip-id="menu-title"
                                            data-tooltip-content={menu.title}>
                                            <div
                                                className={`nav-link ${accordionActiveKey === menu.id ? "active" : ""}`}
                                                role="button">
                                                {menu.icon}
                                                <span>{menu.title}</span>
                                            </div>
                                        </li>
                                    </OverlayTrigger>
                                }
                                return <li key={menu.id} className="nav-item main-menu"
                                           onClick={() => setAccordionActiveKey(accordionActiveKey === menu.id ? "" : menu.id)}>
                                    <div className={`nav-link ${accordionActiveKey === menu.id ? "active" : ""}`}
                                         role="button"
                                    >
                                        {menu.icon}
                                        <span>{menu.title}</span>
                                        <DownArrow/>
                                    </div>
                                    <Accordion.Collapse
                                        eventKey={menu.id}
                                        hidden={!showSidebar}
                                        className={`${showSidebar ? '' : 'd-none'}`}>
                                        <ul className="sub-menu nav nav-pills flex-column">
                                            {menu.subMenus.map((subMenu, index) => {
                                                return (
                                                    <li key={index} className="nav-item">
                                                        <NavLink className="nav-link"
                                                                 to={subMenu.to}>{subMenu.title}</NavLink>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </Accordion.Collapse>
                                </li>
                            } else {
                                return <li className="nav-item" key={menu.id} data-tooltip-id="menu-title"
                                           data-tooltip-content={menu.title}>
                                    <NavLink to={menu.to} className="nav-link">
                                        {menu.icon}
                                        <span>{menu.title}</span>
                                    </NavLink>
                                </li>
                            }
                        })}
                    </Accordion>

                </div>
                <hr/>
                <OverlayTrigger trigger="click" placement="auto" rootClose={true} overlay={(
                    <Popover className={`sub-menu ${showSidebar ? 'hide' : ''}`}>
                        <Popover.Body as='ul' className="nav nav-pills flex-column" style={{width: "200px"}}>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/user-profile">
                                    Your Profile
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/login" onClick={logout}>
                                    Log out
                                </NavLink>
                            </li>
                        </Popover.Body>
                    </Popover>
                )}>
                    <div className="user-card" role="button" data-tooltip-id="menu-title"
                         data-tooltip-content={user && user.firstname}>
                        <div className="details">
                            <img src={user && user.image && 'https://github.com/mdo.png'} alt="" width="38" height="38"
                                 className="rounded-circle"/>
                            <div className="right">
                                <div className="name">{user && user.firstname}</div>
                                <div className="email">{user && user.email}</div>
                            </div>
                        </div>
                        <div className="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25"
                                 fill="none">
                                <path d="M10 17.833L15 12.833" stroke="#737791" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path d="M15 12.833L10 7.83301" stroke="#737791" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </OverlayTrigger>
                <Button className="menu-toggle" onClick={() => {
                    setShowSidebar(!showSidebar)
                    localStorage.setItem('showSidebar', showSidebar ? 'false' : 'true')
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path
                            d="M10.9715 4.47157C11.2318 4.21122 11.2318 3.78911 10.9715 3.52876C10.7111 3.26841 10.289 3.26841 10.0287 3.52876L6.02868 7.52876C5.76833 7.78911 5.76833 8.21122 6.02868 8.47157L10.0287 12.4716C10.289 12.7319 10.7111 12.7319 10.9715 12.4716C11.2318 12.2112 11.2318 11.7891 10.9715 11.5288L7.44289 8.00016L10.9715 4.47157Z"
                            fill="#737791"/>
                    </svg>
                </Button>
            </div>
            {!showSidebar && (
                <Tooltip
                    id="menu-title"
                    place="right"
                    style={{
                        backgroundColor: "#32395F",
                        color: "#FFF",
                        boxShadow: "0px 5px 10px -3.887px rgba(0, 0, 0, 0.25)",
                        zIndex: 1
                    }}
                />
            )}
        </Fragment>
    )
}
